import styled from 'styled-components';

export const Spinner = styled.div`
  width: 36px;
  height: 36px;
  border: 11.2px #e5e5e5 double;
  border-left-style: solid;
  border-radius: 50%;
  animation: spinner-aib1d7 0.75s infinite linear;
  margin: auto;

  @keyframes spinner-aib1d7 {
    to {
      transform: rotate(360deg);
    }
  }
`;
