/**
 * app initial info
 */
const appInfo = {
  name: 'PPA Survey',
  version: '1.0.0',
};

/**
 * app initial environment
 */
const appConfig = {
  api: {
    root: `${process.env.REACT_APP_ROOT_API_URL}/`,
    root2: `${process.env.REACT_APP_ROOT_API_URL_2}/`,
  },
};

/**
 * app initial active config
 */
const appActiveConfig = appConfig;

export { appInfo, appConfig, appActiveConfig };
