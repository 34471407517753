import { fontFamilies } from '@hudoro/neron';
import styled from 'styled-components';
import { colors } from '../../../utils/styles';

export const Wrapper = styled.div`
  max-width: calc(100vw - 40px);

  @media (max-width: 500px) {
    overflow-x: scroll;
  }

  ::-webkit-scrollbar {
    /* width: 10px; */
    height: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.secondary};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const QuestionLabel = styled.p`
  font-family: ${fontFamilies.poppins};
  font-weight: 500;
  font-size: 14px;
  color: #25282b;
  line-height: 21px;
`;

export const QuestionDesc = styled.p`
  font-family: ${fontFamilies.poppins};
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: rgba(37, 40, 43, 0.7);
`;

export const TabsContainer = styled.p`
  margin-top: 10px;
  display: flex;
  gap: 1px;
  justify-content: space-between;
`;

export const LabelInsideRadio = styled.label`
  width: max-content;
  & input {
    display: none;
  }

  & span {
    padding: 5.5px 11px;
    text-align: center;
    border-radius: 100%;
    font-size: 14px;
    background-color: #efefef;
    cursor: pointer;
    font-family: ${fontFamilies.poppins};
    font-weight: 500;
  }

  & input:checked + span {
    background: #0873d1;
    border-color: #0873d1;
    color: white;
  }
`;
