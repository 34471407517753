import { Card, fontFamilies, Grid, Text } from "@hudoro/neron";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import IcCheck from "../../atoms/Icons/IcCheck";

export default function SurveyCard({ survey }) {
  const navigate = useNavigate();
  const params = useParams();

  const onClickSurveyCard = () => {
    navigate(`/${params.type}/${survey.id}`);
  };

  return (
    <Card
      style={{
        cursor: "pointer",
        boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.1)",
      }}
      onClick={onClickSurveyCard}
    >
      <Grid container flexDirection="column" gap={10}>
        <Grid
          container
          flexDirection="column"
          justifyContent="space-between"
          gap={10}
        >
          <Grid
            container
            alignItems="center"
            gap={10}
            justifyContent="space-between"
          >
            <Text variant="h4" style={{ fontSize: "14px", fontWeight: 500 }}>
              {survey.title}
            </Text>
          </Grid>
        </Grid>
        <Text
          variant="p"
          style={{
            fontSize: "12px",
            lineHeight: "18px",
            color: "rgba(37, 40, 43, 0.7)",
            fontFamily: fontFamilies.poppins,
          }}
        >
          {survey.description}
        </Text>
        {survey.isDone && (
          <Grid container justifyContent="flex-end">
            <IcCheck width={24} />
          </Grid>
        )}
      </Grid>
    </Card>
  );
}
