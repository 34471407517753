import {Grid, Text} from "@hudoro/neron";
import React, {Suspense, useState} from "react";
import MainTemplate from "../../components/templates/MainTemplate";
import {StyledText} from "./styles";
import {useParams, useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import {getSurveyDescription} from "../../services/survey";

const InternalAuth = React.lazy(() => import("./InternalAuth"));
const ExsternalAuth = React.lazy(() => import("./ExsternalAuth"));

export default function Authentication() {
  const params = useParams();
  const [description, setDescription] = useState("");
  const [surveyTitle, setSurveyTitle] = useState("");
  const [isError, setIsError] = useState(false);
  const [searchParams] = useSearchParams();

  // To get a specific query parameter

  const fetchDescription = async () => {
    try {
      const response = await getSurveyDescription({
        path: params.type,
      });
      const type = searchParams.get("company");
      localStorage.setItem("type", type);
      setDescription(response.data.data.data.description);
      setSurveyTitle(response.data.data.data.title);
    } catch (error) {
      setIsError(true);
    }
  };

  useEffect(() => {
    fetchDescription();
  }, []);

  if (isError) {
    return (
      <MainTemplate
        title="Something Went Wrong"
        description="Apakah url yang anda masukkan sudah benar"
      />
    );
  }

  return (
    <MainTemplate
      title="Autentikasi"
      description="Mohon masukkan data diri anda terlebih dahulu"
    >
      <img
        src="/iconppa.png"
        width={36}
        alt="PPA LOGO"
        style={{margin: "0 auto"}}
      />
      <Text
        variant="h4"
        style={{
          textAlign: "center",
          fontSize: "13px",
          color: "rgba(0,0,0,.5)",
          textShadow: "0 1px 2px rgba(0,0,0,.1)",
        }}
      >
        {description}
      </Text>
      <Grid
        container
        flexDirection="column"
        gap={20}
        style={{
          padding: "10px 20px 20px",
          borderRadius: 10,
          position: "relative",
        }}
      >
        <Grid
          container
          flexDirection="column"
          gap={20}
          style={{marginBottom: "30px"}}
        >
          <StyledText>{surveyTitle}</StyledText>
        </Grid>
        <Suspense fallback={<div>Loading...</div>}>
          {params.type === "manajemen-risiko" ? (
            <InternalAuth />
          ) : (
            <ExsternalAuth />
          )}
        </Suspense>
      </Grid>
    </MainTemplate>
  );
}
