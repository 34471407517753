import { appActiveConfig } from '../appConfig';

const API_VERSION = 'v1';

const apiEndpoints = {
  getCompanies: `${appActiveConfig.api.root2}${API_VERSION}/surveys/companies`,
  getParentCompanies: `${appActiveConfig.api.root2}${API_VERSION}/surveys`,
  // getSurveys: `${appActiveConfig.api.root}${API_VERSION}/surveys`,
  getSurveys: `${appActiveConfig.api.root2}${API_VERSION}/surveys`,
  // surveyAccess: `${appActiveConfig.api.root}${API_VERSION}/surveys/access`,
  surveyAccess: `${appActiveConfig.api.root2}${API_VERSION}/access`,
  // getQuestions: `${appActiveConfig.api.root}v2/surveys/questions`,
  getQuestions: `${appActiveConfig.api.root2}${API_VERSION}/surveys/questions`,
  submitAnswers: `${appActiveConfig.api.root2}${API_VERSION}/surveys/questions`,
  // submitAnswers: `${appActiveConfig.api.root}${API_VERSION}/surveys/answers`,
};

export { apiEndpoints };
