import API from '../../configs/api';

export const getSurveys = (payload) => {
  return new Promise((resolve, reject) => {
    API.getSurveys(payload)
      .then((res) => resolve({ success: true, data: res }))
      .catch((err) => reject({ success: false, data: err.response.data }));
  });
};

export const surveyAccess = (payload) => {
  return new Promise((resolve, reject) => {
    API.surveyAccess(payload)
      .then((res) => resolve({ success: true, data: res }))
      .catch((err) => reject({ success: false, data: err.response.data }));
  });
};

export const getQuestions = (payload) => {
  return new Promise((resolve, reject) => {
    API.getQuestions(payload)
      .then((res) => resolve({ success: true, data: res }))
      .catch((err) => reject({ success: false, data: err.response.data }));
  });
};

export const submitAnswers = (payload) => {
  return new Promise((resolve, reject) => {
    API.submitAnswers(payload)
      .then((res) => resolve({ success: true, data: res }))
      .catch((err) => reject({ success: false, data: err.response.data }));
  });
};

export const getSubmitAnswersText = (payload) => {
  return new Promise((resolve, reject) => {
    API.getSubmitAnswersText(payload)
      .then((res) => resolve({ success: true, data: res }))
      .catch((err) => reject({ success: false, data: err.response.data }));
  });
};

export const sendNotifEmail = (payload) => {
  return new Promise((resolve, reject) => {
    API.sendNotifEmail(payload)
      .then((res) => resolve({ success: true, data: res }))
      .catch((err) => reject({ success: false, data: err.response.data }));
  });
};

export const getCompanies = (payload) => {
  return new Promise((resolve, reject) => {
    API.getCompanies(payload)
      .then((res) => resolve({ success: true, data: res }))
      .catch((err) => reject({ success: false, data: err.response.data }));
  });
};
export const getParentCompanies = (payload) => {
  return new Promise((resolve, reject) => {
    API.getParentCompanies(payload)
      .then((res) => resolve({ success: true, data: res }))
      .catch((err) => reject({ success: false, data: err.response.data }));
  });
};
export const getCompaniesByParentCompanyId = (payload) => {
  return new Promise((resolve, reject) => {
    API.getCompaniesByParentCompanyId(payload)
      .then((res) => resolve({ success: true, data: res }))
      .catch((err) => reject({ success: false, data: err.response.data }));
  });
};

export const getSurveyDescription = (payload) => {
  return new Promise((resolve, reject) => {
    API.getSurveyDescription(payload)
      .then((res) => resolve({ success: true, data: res }))
      .catch((err) => reject({ success: false, data: err.response.data }));
  });
};
