import styled from 'styled-components';
import { fontFamilies } from '@hudoro/neron';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledLabel = styled.label`
  font-family: ${fontFamilies.poppins};
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #25282b;
`;

export const StyledTextarea = styled.textarea`
  font-family: ${fontFamilies.poppins};
  padding: 12px 20px;
  border: 1px solid #25282b;
  border-radius: 10px;
  resize: none;
  min-height: 88px;
  outline: none;
  background-color: transparent;
`;
