import { Text } from '@hudoro/neron';
import React from 'react';

const HeaderText = ({ title }) => {
  return (
    <Text variant="h1" style={{ fontSize: '36px', fontWeight: 600 }}>
      {title}
    </Text>
  );
};

export default HeaderText;
