import {fontFamilies} from "@hudoro/neron";
import styled from "styled-components";

export const SurveyTitle = styled.p`
  font-family: ${fontFamilies.poppins};
  font-weight: 500;
  font-size: 24px;
  /* line-height: 36px; */
  color: #515355;
`;

export const SurveyInformationContainer = styled.div`
  padding: 14px 20px;
  background: #b1eefc;
  border-radius: 10px;
`;

export const UserContainer = styled.div`
  padding: 14px 20px;
  background: #ffffff;
  border-radius: 10px;
`;
