import {BrowserRouter, Route, Routes} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import Authentication from "./pages/Authentication";
import "./index.css";
import Cookies from "js-cookie";
import SurveyForm from "./pages/SurveyForm";
import Surveys from "./pages/Surveys";

// const url = [
//   "formulir-penilaian-kepuasan-pelanggan",
//   "manajemen-risiko",
//   "formulir-penilaian-kepuasan-pelanggan-2023-semester-1",
// ];

function App() {
  const token = Cookies.get("token");
  return (
    <BrowserRouter>
      <ToastContainer
        draggable={false}
        position="bottom-left"
        autoClose={3000}
        newestOnTop={true}
        limit={3}
        pauseOnFocusLoss
      />
      <Routes>
        <Route
          path="/:type"
          element={token ? <Surveys /> : <Authentication />}
        />
        <Route path="/:type/:surveyId" element={<SurveyForm />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
