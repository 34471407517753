import { Button } from "@hudoro/neron";
import React from "react";
import { colors } from "../../../utils/styles";

export default function StyledButton({ children, ...rest }) {
  return (
    <Button
      {...rest}
      theme={{
        background: colors.secondary,
        foreground: "white",
      }}
    >
      {children}
    </Button>
  );
}
