import React from "react";
import {SurveyInformationContainer} from "./styles";
import {Text, fontFamilies} from "@hudoro/neron";

const text = [
  "NILAI 1 - 5 (NEED IMPROVEMENT / KURANG)",
  "NILAI 6 - 7 (ENOUGH / CUKUP)",
  "NILAI 8 - 9 (GOOD / BAGUS)",
  "NILAI 10 (EXCELLENT / BAGUS SEKALI)",
];

const InformasiKeteranganPenilaian = () => {
  return (
    <SurveyInformationContainer>
      <Text
        variant="h4"
        style={{
          fontSize: "14px",
          color: "#0F3B45",
          fontWeight: 500,
          marginBottom: "7px",
        }}
      >
        Informasi Keterangan Penilaian :
      </Text>
      {text.map((item, index) => (
        <Text
          variant="p"
          style={{
            fontSize: "13px",
            color: "#0F3B45",
            fontFamily: fontFamilies.poppins,
            marginBottom: "2px",
          }}
          key={index}
        >
          {item}
        </Text>
      ))}
    </SurveyInformationContainer>
  );
};

export default InformasiKeteranganPenilaian;
