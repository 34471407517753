import { Grid, Text } from "@hudoro/neron";
import React, { useEffect, useState } from "react";
import { useMultipleChoiceState } from "../../../../recoil/multipleChoice/atoms";

export default function MultipleAnswer({ data }) {
  const [id, setId] = useState([]);
  const [multipleChoice, setMultipleChoice] = useMultipleChoiceState();

  useEffect(() => {
    const defaultCheckedId = data?.component.data
      .filter((item) => item.value)
      .map((data) => {
        return { id: data.id };
      });
    if (defaultCheckedId.length) {
      const newData = {
        questionId: data.id,
        questionType: "MULTIPLE_CHOICE",
        answerMultipleChoices: {
          value: defaultCheckedId,
        },
      };
      setMultipleChoice((data) => [...data, newData]);
    }
    setId(defaultCheckedId);
  }, []);

  const handleChange = (answer) => {
    let findData = multipleChoice.find((item) => item.questionId === data.id);
    if (!findData) {
      const newData = {
        questionId: data.id,
        questionType: "MULTIPLE_CHOICE",
        answerMultipleChoices: {
          value: [{ id: answer.id }],
        },
      };
      setMultipleChoice((data) => [...data, newData]);
    } else {
      const findDataByAnswerId = findData.answerMultipleChoices.value.find(
        (data) => data.id === answer.id
      );
      if (!findDataByAnswerId) {
        const answerValue = findData.answerMultipleChoices.value;
        const newData = {
          questionId: data.id,
          questionType: "MULTIPLE_CHOICE",
          answerMultipleChoices: {
            value: [...answerValue, { id: answer.id }],
          },
        };
        const filteredData = multipleChoice.filter(
          (item) => item.questionId !== data.id
        );
        setMultipleChoice([...filteredData, newData]);
      } else {
        const newDataFilter = findData.answerMultipleChoices.value.filter(
          (item) => item.id !== findDataByAnswerId.id
        );
        const newData = {
          questionId: data.id,
          questionType: "MULTIPLE_CHOICE",
          answerMultipleChoices: {
            value: [...newDataFilter],
          },
        };
        const filteredData = multipleChoice.filter(
          (item) => item.questionId !== data.id
        );
        setMultipleChoice([...filteredData, newData]);
      }
    }
  };

  return (
    <Grid container flexDirection="column" gap={20} style={{ flex: 1 }}>
      {data?.component.data?.map((answer) => {
        // console.log(data.id);
        // console.log("data", multipleChoice);
        const findData = multipleChoice.find(
          (item) => item.questionId === data.id
        );
        const isContainAnswer = findData?.answerMultipleChoices.value.find(
          (item) => item.id === answer.id
        );
        console.log(isContainAnswer);
        const dataLength = findData?.answerMultipleChoices.value.length;
        return (
          <Grid
            container
            gap={10}
            style={{ flexWrap: "nowrap" }}
            key={answer.id}
          >
            <Grid container alignItems="center" justifyContent="center">
              <input
                disabled={
                  !isContainAnswer && dataLength && dataLength >= 7 && true
                }
                value={answer.id}
                name={`checkbox-${data?.id}`}
                type="checkbox"
                defaultChecked={answer.value}
                style={{
                  width: 24,
                }}
                onChange={() => handleChange(answer)}
              />
            </Grid>
            <Grid container>
              <Text
                variant="p"
                style={{
                  color: `${
                    !isContainAnswer && dataLength && dataLength >= 7
                      ? "#aaa"
                      : "black"
                  }`,
                }}
              >
                {answer.name}
              </Text>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}
