import React from 'react';

const IcCheck = ({ ...rest }) => {
  return (
    <svg {...rest} viewBox="0 0 131 127" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="21.5" y="20" width="88" height="88" rx="44" fill="#CEEEA5" />
      <path
        d="M85.5 50L58 78L45.5 65.2727"
        stroke="#5C9611"
        stroke-width="7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <g filter="url(#filter0_f_256_27188)">
        <ellipse cx="65.5" cy="63.5" rx="62" ry="60.5" fill="#CEEEA5" fill-opacity="0.4" />
      </g>
      <defs>
        <filter
          id="filter0_f_256_27188"
          x="0.5"
          y="0"
          width="130"
          height="127"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_256_27188" />
        </filter>
      </defs>
    </svg>
  );
};

export default IcCheck;
