import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

const multipleChoiceAtom = atom({
  key: 'multipleChoice',
  default: [],
});

export const useMultipleChoiceValue = () => useRecoilValue(multipleChoiceAtom);
export const useSetMultipleChoice = () => useSetRecoilState(multipleChoiceAtom);
export const useMultipleChoiceState = () => useRecoilState(multipleChoiceAtom);
