import { toast } from 'react-toastify';

export const notify = (message, type) => {
  switch (type) {
    case 'success':
      toast.success(`${message}`);
      break;
    case 'error':
      toast.error(`${message}`);
      break;
    case 'warning':
      toast.warn(`${message}`);
      break;
    case 'info':
      toast.info(`${message}`);
      break;
    case 'dark':
      toast.dark(`${message}`);
      break;
    default:
      toast(`${message}`);
      break;
  }
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function validateDomainNameEmailAddress(domainEmail, userEmail) {
  const typeAcceptEmail = domainEmail.map((item) => item.domainEmail);
  const lastUserEmail = userEmail.split('@')[1];
  const checkedEmail = typeAcceptEmail.some((v) => lastUserEmail.includes(v));
  console.log('userEmail', checkedEmail);

  return {
    isNotMatch: checkedEmail,
    typeAcceptEmail,
  };
}
