import {Input} from "@hudoro/neron";
import React from "react";
import LinearScaleInput from "../LinearScaleInput";
// import LongAnswer from "../LongAnswer";
import MultipleChoice from "../MultipleChoice";
import QuestionWrapper from "../QuestionWrapper";
import ShortAnswerInput from "../ShortAnswerInput";

export default function QuestionItem({
  question,
  isBorder,
  questionValue,
  setQuestionValue,
  errorId,
}) {
  const renderQuestionAccordingToType = () => {
    // switch (question.type) {
    switch (question.component.name) {
      case "MULTIPLE_CHOICE":
        return <MultipleChoice data={question} />;
      case "input":
        return <Input />;
      // the new begin
      case "SHORT_ANSWER":
        return (
          <ShortAnswerInput
            data={question}
            questionValue={questionValue}
            setQuestionValue={setQuestionValue}
          />
        );
      case "LINEAR_SCALE":
        return (
          <LinearScaleInput
            data={question}
            questionValue={questionValue}
            setQuestionValue={setQuestionValue}
            errorId={errorId}
          />
        );
      // case "LONG_ANSWER":
      //   return <LongAnswer data={question} />;
      default:
        return null;
    }
  };
  return (
    <QuestionWrapper
      style={{
        borderTop: !isBorder ? "1px solid #7C7E80" : "0",
        borderRadius: "0",
        padding: 0,
        paddingTop: "8px",
      }}
    >
      {renderQuestionAccordingToType()}
    </QuestionWrapper>
  );
}
