import axios from 'axios';

/**
 * @name apiInstance
 * @description API instance dari axios untuk default config
 */

const apiInstance = axios.create({
  baseURL: '',
  timeout: 60000,
  validateStatus: (status) => status >= 200 && status < 300,
});

class ApiRequest {
  static get(route, token = false) {
    return (payload) => this.request('GET', route, payload, token);
  }

  static put(route, token = false) {
    return (payload) => this.request('PUT', route, payload, token);
  }

  static post(route, token = false) {
    return (payload) => this.request('POST', route, payload, token);
  }

  static delete(route, token = false) {
    return (payload) => this.request('DELETE', route, payload, token);
  }

  static patch(route, token = false) {
    return (payload) => this.request('PATCH', route, payload, token);
  }

  static resolveParams(params) {
    const paramsResult = [];
    Object.keys(params).map((e) => paramsResult.push(`${e}=${params[e]}`));
    return paramsResult.join('&');
  }

  static async request(method, route, payload = {}, token) {
    const path = payload.path ? `/${payload.path}` : '';

    const params = payload.params ? `?${this.resolveParams(payload.params)}` : '';

    const customUrl = payload.url ? payload.url : '';

    const baseHeaders = {
      Tenant: '',
      'Content-Type': payload.type === 'form-data' ? 'multipart/form-data' : 'application/json',
    };

    if (token) {
      // const token = getAndDecryptData(
      //   'token',
      //   process.env.REACT_APP_TOKEN_PASSWORD,
      // );
      baseHeaders.Authorization = `Bearer ${token || ''}`;
    }

    const requestPayload = {
      url: customUrl.length > 0 ? customUrl : route + path + params,
      method,
      headers: payload.headers ? { ...baseHeaders, ...payload.headers } : baseHeaders,
      data: payload.body ? payload.body : {},
    };

    // console.log(
    //   `REQUEST - ${requestPayload.method} - ${requestPayload.url}`,
    //   requestPayload
    // );

    try {
      const response = await apiInstance.request(requestPayload);
      // console.log(`RESPONSE SUCCESS - ${requestPayload.method} - ${requestPayload.url}`, response);
      return response;
    } catch (err) {
      // console.log(`RESPONSE ERROR - ${requestPayload.method} - ${requestPayload.url}`, err);
      // throw err;
    }
  }
}

export default ApiRequest;
