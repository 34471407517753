import { Grid, Input, Text } from "@hudoro/neron";
import React from "react";
import { colors } from "../../../utils/styles";
import MultipleAnswer from "./MultipleAnswer";
// import SingleAnswer from './SingleAnswer';

export default function MultipleChoice({ data }) {
  return (
    <Grid container flexDirection="column" gap={10}>
      <Grid container flexDirection="column" gap={10}>
        <Text
          variant="p"
          style={{
            fontWeight: 700,
            fontSize: 14,
          }}
        >
          {data.name || "Title"}
        </Text>
        <Text variant="mute" style={{ color: colors.secondary }}>
          pilih {data?.component?.minimumChoice} jenis resiko
        </Text>
      </Grid>
      <Grid container flexDirection="column" gap={10}>
        {/* {data.isMultiple ? <MultipleAnswer data={data} /> : <SingleAnswer data={data} />} */}
        <MultipleAnswer data={data} />
      </Grid>
      {/* {data?.isInput && (
        <Input
          name={`input-${data.id}`}
          defaultValue={data?.inputValue}
          label="Sebutkan hal lain jika ada"
          disabled={data?.isAnswered}
        />
      )} */}
    </Grid>
  );
}
