import apiRequest from './config';
import { apiEndpoints } from './url';

const API = {};
API.getQuestions = apiRequest.get(apiEndpoints.getQuestions);
API.surveyAccess = apiRequest.post(apiEndpoints.surveyAccess);
API.getSurveys = apiRequest.get(apiEndpoints.getSurveys, true);
API.submitAnswers = apiRequest.post(apiEndpoints.submitAnswers);
API.getSubmitAnswersText = apiRequest.get(apiEndpoints.submitAnswers, true);
API.sendNotifEmail = apiRequest.post(apiEndpoints.submitAnswers, true);
API.getCompanies = apiRequest.get(apiEndpoints.getCompanies);
API.getParentCompanies = apiRequest.get(apiEndpoints.getParentCompanies);
API.getCompaniesByParentCompanyId = apiRequest.get(apiEndpoints.getCompanies);
API.getSurveyDescription = apiRequest.get(apiEndpoints.getSurveys);

export default API;
