import {Grid, Text} from "@hudoro/neron";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {colors} from "../../../utils/styles";
import ContentWrapper from "../../atoms/ContentWrapper";
import MainWrapper from "../../atoms/MainWrapper";
import StyledButton from "../../atoms/StyledButton";

export default function MainTemplate({
  title = "Title",
  description = "Description",
  children,
  withLogOut = false,
}) {
  const navigate = useNavigate();
  const params = useParams();
  // To get a specific query parameter

  const onLogOut = () => {
    // localStorage.clear();
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    const type = localStorage.getItem("type");
    navigate(`/${params.type}?company=${type}`, {replace: true});
    navigate(0);
  };
  return (
    <MainWrapper>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{
          backgroundColor: colors.primary,
          padding: 20,
          width: "100%",
        }}
      >
        <Grid container flexDirection="column" gap={10}>
          <Text variant="h4" style={{color: "white"}}>
            {title}
          </Text>
          <Text variant="p" style={{color: "white", lineHeight: 1.6}}>
            {description}
          </Text>
        </Grid>
        {withLogOut && (
          <Grid container>
            <StyledButton variant="primary" onClick={onLogOut}>
              Keluar
            </StyledButton>
          </Grid>
        )}
      </Grid>
      <ContentWrapper
        style={{background: "#f2f2f2"}}
        container
        flexDirection="column"
        gap={20}
      >
        {children}
      </ContentWrapper>
    </MainWrapper>
  );
}
