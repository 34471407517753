import { Grid } from '@hudoro/neron';
import styled from 'styled-components';

const ContentWrapper = styled(Grid).attrs({
  container: true,
})`
  max-width: 600px;
  margin: auto;
  min-height: 100vh;
  padding: 30px 24px;
`;

export default ContentWrapper;
