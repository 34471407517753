import {Text} from "@hudoro/neron";
import React from "react";

const Ratarata = ({sections, questionValue}) => {
  const totalData = [];

  for (let i of questionValue || []) {
    if (i.answer) {
      if (Number(i.answer.value)) {
        totalData.push(Number(i.answer.value));
      }
    }
  }

  return (
    <Text variant="h4" style={{textAlign: "center"}}>
      {totalData.length
        ? `TOTAL POINT : ${(
            totalData.reduce((acc, val) => acc + val, 0) / totalData.length
          ).toFixed(2)}`
        : null}
    </Text>
  );
};

export default Ratarata;
