import { Text } from '@hudoro/neron';
import styled from 'styled-components';

export const StyledText = styled(Text).attrs({
  variant: 'h3',
})`
  margin: auto;
  font-size: 30px;
  text-align: center;
`;
