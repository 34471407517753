import React from "react";
import {Container, StyledLabel, StyledTextarea} from "./styles";

const ShortAnswerInput = ({data, questionValue, setQuestionValue}) => {
  const questionType = data.component.name;
  const questionId = data.id;

  const handleChangeValue = (value) => {
    const newValue = [...questionValue];
    const findPosition = newValue.findIndex(
      (item) => item.questionId === data.id
    );
    if (findPosition < 0) {
      newValue.push({
        questionId: data.id,
        questionType: questionType,
        answer: {
          value: String(value),
        },
      });
      setQuestionValue(newValue);
      return;
    } else {
      newValue[findPosition].answer.value = String(value);
      setQuestionValue(newValue);
    }
  };
  return (
    <Container>
      <StyledLabel>{data.name}</StyledLabel>
      <StyledTextarea
        name={`input/${questionId}/${questionType}`}
        required={data.isRequired}
        defaultValue={data.answer?.value}
        onChange={(e) => handleChangeValue(e.target.value)}
      />
    </Container>
  );
};

export default ShortAnswerInput;
