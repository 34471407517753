import React, { useEffect, useState, useCallback } from "react";
import { Text } from "@hudoro/neron";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import MainTemplate from "../../components/templates/MainTemplate";
import { getSurveys } from "../../services/survey";
import HeaderText from "../../components/atoms/HeaderText";
import SurveyCard from "../../components/molecules/SurveyCard";

export default function Surveys() {
  const [surveys, setSurveys] = useState();
  const [isloading, setIsloading] = useState(false);
  const params = useParams();

  const fetchSurveys = useCallback(async () => {
    setIsloading(true);
    try {
      const response = await getSurveys({
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
        path: `${params.type}`,
      });
      setIsloading(false);
      setSurveys(response.data.data.data);
    } catch (err) {
      setIsloading(false);
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchSurveys();
  }, [fetchSurveys]);

  return (
    <MainTemplate
      withLogOut
      title="Survey"
      description="Pilih survey yang ingin diisi"
    >
      <HeaderText />
      <Text
        variant="h3"
        style={{ fontSize: "14px", marginTop: "12px", fontWeight: 500 }}
      >
        List Survey
      </Text>
      {isloading && (
        <Text
          variant="h3"
          style={{
            fontSize: "14px",
            marginTop: "12px",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          Loading...
        </Text>
      )}
      {surveys && <SurveyCard key={surveys.id} survey={surveys} />}
    </MainTemplate>
  );
}
